import React from 'react';
import PropTypes from 'prop-types';
import { Page, Button } from 'tabler-react';
import AccessControl from '../AccessControl';

import { userRoles } from '../../utils/roles';
import { languagesList } from '../../utils/constans';

class LobbyHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = { language: 'en' };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.activeLanguage !== this.props.activeLanguage) {
      this.setState({ language: this.props.activeLanguage });
    }
  }

  handleChange = (e) => {
    this.setState({ language: e.target.value }, () => {
      this.props.changeLanguage(this.state.language);
    });
  };

  render() {
    const { saveAsDraft, publishLobby } = this.props;

    return (
      <Page.Header>
        <div className="flexBox">
          <Page.Title>Lobby</Page.Title>

          <div className="flexBox right align-content-center align-items-center m-0">
            <label className="m-0">
              Language:
              <select value={this.state.language} onChange={this.handleChange}>
                {languagesList.map((lang, index) => {
                  return (
                    <option key={index} value={lang.value}>
                      {lang.label}
                    </option>
                  );
                })}
              </select>
            </label>

            {/* <Button type="button" color="primary" className="m-0">
                            Preview
                        </Button> */}
            <Button type="button" color="primary" className="m-0" onClick={saveAsDraft}>
              Save as draft
            </Button>
            <AccessControl roles={[userRoles.superAdmin, userRoles.admin, userRoles.brand, userRoles.resultsOperator]}>
              <Button form="lobbyForm" type="submit" color="primary" className="m-0" onClick={publishLobby}>
                Publish
              </Button>
            </AccessControl>
          </div>
        </div>
      </Page.Header>
    );
  }
}

LobbyHeader.propTypes = {
  publishLobby: PropTypes.func,
  saveAsDraft: PropTypes.func,
  changeLanguage: PropTypes.func,
  activeLanguage: PropTypes.string,
  versionId: PropTypes.string,
};

export default LobbyHeader;
