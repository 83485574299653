export const isPlayerTested = (state) => {
  const { singlePlayer } = state;

  if (!singlePlayer.data) {
    return false;
  }

  return singlePlayer.data.isTest;
};

export const getRetailShopId = (state) => {
  const { singlePlayer } = state;

  if (!singlePlayer.data) {
    return false;
  }

  return singlePlayer.data.retailShopId;
};

export const getPlayerBrand = (state) => {
  const { singlePlayer } = state;

  if (!singlePlayer.data) {
    return false;
  }

  return singlePlayer.data.brand ? singlePlayer.data.brand.name : '';
};
