/* eslint-disable jsx-quotes */
import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import httpClient from '../../../services/httpClient';
import AccessControl from '../../AccessControl';
import { userRoles } from '../../../utils/roles';
import Select from 'react-select';
import { Button, Icon } from 'tabler-react';
import moment from 'moment-timezone';

export default function DrawsManual() {
  //states
  const [model, setModel] = useState({
    lotteryId: null,
    timezone: null,
    drawDate: null,
    drawTime: null,
    closeDrawDate: null,
    closeDrawTime: null,
    openDrawDate: null,
    openDrawTime: null,
  });
  const [lotteries, setLotteries] = useState([]);
  const [timezones, setTimezones] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(true);
  const [lotteryName, setLotteryName] = useState('');
  const [responseStatus, setResponseStatus] = useState(0);

  const history = useHistory();

  //params
  const params = useParams();

  //initial load
  const load = async () => {
    try {
      //start loading
      setLoading(true);

      //fetch the lottery
      const objResponseLottery = await httpClient.get(`/api/v1/games/${params.lotteryId}`);
      const objLottery = await objResponseLottery.data;
      if (objLottery) {
        setModel({ ...model, lotteryId: objLottery.id });
        setLotteryName(objLottery.name);
      }

      //fetch the lottery
      const objResponse = await httpClient.get(`/api/v1/games`);
      //filters  lotteries that have allowsManualDrawsManagement
      let arrLottery = objResponse.data.filter(
        (obj) => obj.lottery && obj.lottery.structure && obj.lottery.structure.allowsManualDrawsManagement
      );
      //map lotteries for required object
      arrLottery = arrLottery.map((obj) => {
        return {
          label: `${obj.name}`,
          value: obj.id,
        };
      });

      setLotteries(arrLottery);

      //fetch the timezones
      const objResponseTZ = await httpClient.get(`/api/v1/system-consts/timezones`);
      const arrTimezones = objResponseTZ.data.map((str) => {
        return {
          label: str,
          value: str,
        };
      });
      setTimezones(arrTimezones);

      //end loading
      setLoading(false);
    } catch (err) {
      if (err.response) {
        setError(err.response.data);
      }
    }

    //end loading
    setLoading(false);
  };

  // on mount
  useEffect(() => {
    load();
  }, []); //intCurrentPage

  //loading
  if (loading) {
    return (
      <div className="spinner-container text-center mt-5">
        <svg className="spinner" width="50px" height="50px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
          <circle className="path" fill="none" cx="33" cy="33" r="30"></circle>
        </svg>
      </div>
    );
  }

  //error
  if (error && error.code) {
    return (
      <div className="mt-4">
        <div className="card">
          <div className="row row row-cards row-deck"></div>
          <div className="card-status bg-blue"></div>
          <div className="card-header">
            <h3 className="card-title">Error</h3>
          </div>
          <div className="card-body">
            <div className="add-brand-form">
              <div className="pb-2">
                <p style={{ color: 'red' }}>{error.message}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  //success
  if (responseStatus === 200) {
    return (
      <div className="mt-4">
        <div className="card">
          <div className="row row row-cards row-deck">
            <Button
              className="btn btn-primary ml-6 mt-4"
              onClick={() => {
                history.push(`/lottery/lotteries/${params.lotteryId}`);
              }}
            >
              Back to lottery
            </Button>
          </div>
          <div className="card-status bg-blue"></div>
          <div className="card-header">
            <h3 className="card-title">Success</h3>
          </div>
          <div className="card-body">
            <div className="add-brand-form">
              <div className="pb-2">
                <p style={{ color: 'darkgreen' }}>Draw submitted successfully.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // else if (error.length) {
  //   //from app
  //   return (
  //     <div className='pt-4' style={{ color: 'red' }}>
  //       <h1>Error</h1>
  //       <div dangerouslySetInnerHTML={{ __html: error.join('<br />') }}></div>
  //     </div>
  //   );
  // }

  const handleManualDraw = async () => {
    const arrError = [];
    if (!model.lotteryId) {
      arrError.push('-Select lottery');
    }
    if (!model.timezone) {
      arrError.push('-Select timezone');
    }
    if (!model.drawDate) {
      arrError.push('-Select draw date');
    }
    if (!model.drawTime) {
      arrError.push('-Select draw time');
    }
    if (!model.closeDrawDate) {
      arrError.push('-Select close draw date');
    }
    if (!model.closeDrawTime) {
      arrError.push('-Select close draw time');
    }
    if (!model.openDrawDate) {
      arrError.push('-Select open draw date');
    }
    if (!model.openDrawTime) {
      arrError.push('-Select open draw time');
    }

    if (arrError.length) {
      let strError = 'Please correct the following error(s)\r\n\r\n';
      strError += arrError.join('\r\n');
      window.alert(strError);
      return;
    }

    if (confirm('Are you sure you want to proceed with the manual draw?')) {
      try {
        //start loading
        setLoading(true);

        const strDrawDateTimeUTC = moment(model.drawDate + ' ' + model.drawTime)
          .utc()
          .format('YYYY-MM-DDTHH:mm:ss+00:00');
        const strCloseDateTimeUTC = moment(model.closeDrawDate + ' ' + model.closeDrawTime)
          .utc()
          .format('YYYY-MM-DDTHH:mm:ss+00:00');
        const strOpenDateTimeUTC = moment(model.openDrawDate + ' ' + model.openDrawTime)
          .utc()
          .format('YYYY-MM-DDTHH:mm:ss+00:00');

        //post the manual draw
        const objRequest = {};
        objRequest.closeDatetime = strCloseDateTimeUTC;
        objRequest.drawDatetime = strDrawDateTimeUTC;
        objRequest.openDatetime = strOpenDateTimeUTC;
        const objResponse = await httpClient.post(`/api/v1/game/${model.lotteryId}/draws`, objRequest);

        //set response status to display a success message
        setResponseStatus(objResponse.status);

        //end loading
        setLoading(false);
      } catch (err) {
        if (err.response) {
          setError(err.response.data);
        }
      }

      //end loading
      setLoading(false);
    }
  };

  const getLocalDateTime = (drawDate, drawTime) => {
    const strDateTime = drawDate + ' ' + drawTime;

    return moment.tz(strDateTime, model.timezone.value).local().format('DD/MM/YY HH:mm');
  };

  const getGlobalDateTime = (drawDate, drawTime) => {
    const strDateTime = drawDate + ' ' + drawTime;

    return moment.tz(strDateTime, model.timezone.value).tz('UTC').format('DD/MM/YY HH:mm');
  };

  // eslint-disable-next-line react/prop-types
  const RenderLocalDateTime = ({ strDateTime }) => {
    return (
      <div className="displayed">
        <Icon prefix="fa" name="user" />
        &nbsp;
        <label>
          <span style={{ color: 'darkgreen' }}>Your Local time: {strDateTime}</span>
        </label>
      </div>
    );
  };

  // eslint-disable-next-line react/prop-types
  const RenderGlobalDateTime = ({ strDateTime }) => {
    return (
      <div className="displayed">
        <Icon prefix="fa" name="globe" />
        &nbsp;
        <label>
          <span style={{ color: 'darkgreen' }}>Global UTC time: {strDateTime}</span>
        </label>
      </div>
    );
  };

  //return the component
  return (
    <AccessControl
      roles={[userRoles.superAdmin, userRoles.admin, userRoles.brand, userRoles.resultsOperator, userRoles.reportsManager]}
      forbiddenRedirect={'/'}
    >
      <div className="mt-4">
        <div className="card">
          <div className="row row row-cards row-deck">
            <Button
              className="btn btn-primary ml-6 mt-4"
              onClick={() => {
                history.push(`/lottery/lotteries/${params.lotteryId}`);
              }}
            >
              Back to lottery
            </Button>
          </div>
          <div className="card-status bg-blue"></div>
          <div className="card-header">
            <h3 className="card-title">Create manual draw for '{lotteryName}'</h3>
          </div>
          <div className="card-body">
            <div className="add-brand-form">
              <div className="pb-2">
                <span style={{ color: 'red' }}>
                  Note: Created draw will be numbered with next value regardless of draw time. You should create draws
                  following real draw date in order to keep the draw number ascending.
                </span>
              </div>
              <div className="form-group field">
                <label className="" htmlFor="lottery">
                  Lottery
                </label>
                {params.lotteryId && (
                  <Select
                    options={lotteries}
                    className="mb-2"
                    onChange={(obj) => {
                      setModel({ ...model, lotteryId: obj.value });
                      // history.push(`/draws-manual/${obj.value}`);
                      window.location.replace(`/draws-manual/${obj.value}`);
                    }}
                    // value={model.lotteryId}
                    defaultValue={{
                      label: lotteryName,
                      value: params.lotteryId,
                    }}
                    styles={{
                      menu: (provided) => ({ ...provided, zIndex: 9999 }),
                    }}
                  />
                )}
              </div>

              <div className="form-group field">
                <label className="" htmlFor="lottery">
                  Timezone
                </label>
                <Select
                  options={timezones}
                  className="mb-2"
                  onChange={(value) => {
                    setModel({ ...model, timezone: value });
                  }}
                  value={model.timezone}
                  styles={{
                    menu: (provided) => ({ ...provided, zIndex: 9999 }),
                  }}
                />
              </div>

              <div className="form-group field">
                <label className="" htmlFor="drawDate">
                  Draw date time - time of the lottery
                </label>
                <div className="d-flex">
                  <input
                    type="date"
                    id="drawDate"
                    className=" form-control"
                    name="drawDate"
                    value={model.drawDate}
                    onChange={(ev) => {
                      setModel({ ...model, drawDate: ev.target.value });
                    }}
                  />
                  &nbsp;
                  <input
                    type="time"
                    id="drawTime"
                    className=" form-control"
                    name="drawTime"
                    value={model.drawTime}
                    onChange={(ev) => {
                      setModel({ ...model, drawTime: ev.target.value });
                    }}
                  />
                </div>
                {model.drawDate && model.drawTime && model.timezone && (
                  <RenderLocalDateTime strDateTime={getLocalDateTime(model.drawDate, model.drawTime)} />
                )}
                {model.drawDate && model.drawTime && model.timezone && (
                  <RenderGlobalDateTime strDateTime={getGlobalDateTime(model.drawDate, model.drawTime)} />
                )}
              </div>

              <div className="form-group field">
                <label className="" htmlFor="drawDate">
                  Close date time - time of the lottery
                </label>
                <div className="d-flex">
                  <input
                    type="date"
                    id="closeDrawDate"
                    className=" form-control"
                    name="closeDrawDate"
                    value={model.closeDrawDate}
                    onChange={(ev) => {
                      setModel({ ...model, closeDrawDate: ev.target.value });
                    }}
                  />
                  &nbsp;
                  <input
                    type="time"
                    id="closeDrawTime"
                    className=" form-control"
                    name="closeDrawTime"
                    value={model.closeDrawTime}
                    onChange={(ev) => {
                      setModel({ ...model, closeDrawTime: ev.target.value });
                    }}
                  />
                </div>
                {model.closeDrawDate && model.closeDrawTime && model.timezone && (
                  <RenderLocalDateTime strDateTime={getLocalDateTime(model.closeDrawDate, model.closeDrawTime)} />
                )}
                {model.closeDrawDate && model.closeDrawTime && model.timezone && (
                  <RenderGlobalDateTime strDateTime={getGlobalDateTime(model.closeDrawDate, model.closeDrawTime)} />
                )}
              </div>

              <div className="form-group field">
                <label className="" htmlFor="drawDate">
                  Open date time - time of the lottery
                </label>
                <div className="d-flex">
                  <input
                    type="date"
                    id="openDrawDate"
                    className=" form-control"
                    name="openDrawDate"
                    value={model.openDrawDate}
                    onChange={(ev) => {
                      setModel({ ...model, openDrawDate: ev.target.value });
                    }}
                  />
                  &nbsp;
                  <input
                    type="time"
                    id="openDrawTime"
                    className=" form-control"
                    name="openDrawTime"
                    value={model.openDrawTime}
                    onChange={(ev) => {
                      setModel({ ...model, openDrawTime: ev.target.value });
                    }}
                  />
                </div>
                {model.openDrawDate && model.openDrawTime && model.timezone && (
                  <RenderLocalDateTime strDateTime={getLocalDateTime(model.openDrawDate, model.openDrawTime)} />
                )}
                {model.openDrawDate && model.openDrawTime && model.timezone && (
                  <RenderGlobalDateTime strDateTime={getGlobalDateTime(model.openDrawDate, model.openDrawTime)} />
                )}
              </div>

              <div className="form-group field">
                <button onClick={handleManualDraw} className="btn btn-primary" type="submit">
                  Submit Manual Draw
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AccessControl>
  );
}
