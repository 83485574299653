import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Page } from 'tabler-react';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';

import CustomTable from '../../Reusable/Table/index';
import TicketFilters from '../../Reusable/WinningsFilter/TicketFilters';
import Spinner from '../../Reusable/Spinner/Spinner';
import { ErrorHandler } from '../../Reusable/ErrorHandler/index';
import AccessControl from '../../AccessControl';

import withPageConfig from '../../hoc/withPageConfig';

import {
  changeEntries,
  getTickets,
  changePage,
  changeDraw,
  changeTicketsType,
  resetTickets,
} from '../../../actions/ticketsActions';
import { getDraw } from '../../../actions/draws';

import { userRoles } from '../../../utils/roles';
import { TICKETS_PAGE } from '../../../utils/pagesConstans';
import { getTicketsColumns } from '../../../utils/tickets/tickets';

class Tickets extends React.Component {
  componentDidMount() {
    const { changeDraw, location, changeTicketsType, type } = this.props;
    const drawId = queryString.parse(location.search).drawId;

    changeTicketsType(type);

    if (drawId) {
      changeDraw(drawId);
    }

    this.getTickets();
  }

  componentWillUnmount() {
    const { resetTickets } = this.props;

    resetTickets();
  }

  getTickets = (page) => {
    const { pagiNumber, type, getTickets } = this.props;
    const properPage = page || pagiNumber;

    getTickets(properPage, type);
  };

  handlePageChange = (pageNumber) => {
    const { onPaginationPageChange } = this.props;

    this.getTickets(pageNumber);
    onPaginationPageChange(pageNumber);
  };

  handleChangeEntriesNumber = (pageNumber, entries) => {
    const { changeEntries } = this.props;

    changeEntries(entries);
    this.getTickets(1);
  };

  renderContent() {
    const { isLoading, error, tickets, ticketsHeaders, type } = this.props;

    if (isLoading && !tickets) {
      return <Spinner />;
    }

    if (error) {
      return <ErrorHandler error={error} />;
    }

    if (!tickets && !ticketsHeaders) {
      return null;
    }

    return (
      <CustomTable
        onChangeEntries={this.handleChangeEntriesNumber}
        onPageChanged={this.handlePageChange}
        onPhraseSearched={this.handlePhraseSearch}
        paginHeaders={ticketsHeaders}
        loading={isLoading}
        data={tickets}
        columns={getTicketsColumns(type)}
      />
    );
  }

  render() {
    const { title, type } = this.props;

    return (
      <AccessControl
        roles={[userRoles.superAdmin, userRoles.admin, userRoles.brand, userRoles.resultsOperator, userRoles.support, userRoles.reportsManager]}
        forbiddenRedirect={'/'}
      >
        <Page.Header>
          <div className="d-flex align-items-center justify-content-between w-100">
            <Page.Title>{title}</Page.Title>
          </div>
        </Page.Header>
        <TicketFilters getTickets={this.getTickets} type={type} />
        {this.renderContent()}
      </AccessControl>
    );
  }
}

Tickets.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,

  isLoading: PropTypes.bool,

  pagiNumber: PropTypes.number,

  tickets: PropTypes.array,

  ticketsHeaders: PropTypes.object,
  error: PropTypes.object,
  location: PropTypes.object,

  getTickets: PropTypes.func,
  changeEntries: PropTypes.func,
  changeDraw: PropTypes.func,
  changeTicketsType: PropTypes.func,
  onPaginationPageChange: PropTypes.func,
  changePage: PropTypes.func,
  resetTickets: PropTypes.func,
};

export default withRouter(
  connect(
    (state) => ({
      tickets: state.tickets.data,
      ticketsHeaders: state.tickets.headers,
      isLoading: state.tickets.isPending,
      error: state.tickets.error,
    }),
    {
      getTickets,
      changeEntries,
      changeDraw,
      changePage,
      changeTicketsType,
      resetTickets,
      getDraw,
    }
  )(withPageConfig(TICKETS_PAGE)(Tickets))
);
