import React from 'react';
import { Page } from 'tabler-react';
import propTypes from 'prop-types';

import AccessControl from '../../AccessControl';
import Management from '../../Reusable/Management/Management';

import { userRoles } from '../../../utils/roles';

class ManagementTab extends React.Component {
  render() {
    const { draw, postManagementAction } = this.props;

    return (
      <Page>
        <Page.Header>
          <Page.Title>Management</Page.Title>
        </Page.Header>
        <div className="row">
          <AccessControl roles={[userRoles.superAdmin, userRoles.admin, userRoles.brand, userRoles.resultsOperator]}>
            <Management
              onButtonClick={(actionName) => postManagementAction(draw.id, actionName, 'Game-draw')}
              status={draw.status}
              cancelHint="Cancels not resolved draw, all paid tickets are refunded"
              voidHint="Voids resolved draw, all winnings from draw are canceled"
              downloadHint="Download Tickets CSV"
              type="game-draw"
              name="Draw"
              draw={draw}
            />
          </AccessControl>
        </div>
      </Page>
    );
  }
}

ManagementTab.propTypes = {
  draw: propTypes.object,
  postManagementAction: propTypes.func,
};

export default ManagementTab;
