import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Page } from 'tabler-react';
import { Link } from 'react-router-dom';

import CustomTable from '../../Reusable/Table/index';
import { ErrorHandler } from '../../Reusable/ErrorHandler/index';
import Spinner from '../../Reusable/Spinner/Spinner';
import AccessControl from '../../AccessControl';
import LotteryFilter from '../../Reusable/LotteryFilter/LotteryFilter';

import withPageSettings from '../../hoc/withPageConfig';

import { userRoles } from '../../../utils/roles';
import { LOTTERIES_PAGE } from '../../../utils/pagesConstans';

import { getLotteries, deleteLottery, changeEntries } from '../../../actions/lotteryActions';
import { changeLotteryType } from '../../../actions/draws';
import { changeProvider } from '../../../actions/providerDraws';

class LotteryList extends React.Component {
  componentDidMount() {
    const { pagiNumber } = this.props;

    this.props.getLotteries(pagiNumber);
  }

  handleProviderDrawClick = (gameResultAgencyConfig) => {
    const { changeProvider, history } = this.props;
    const provider = {
      label: gameResultAgencyConfig.name,
      value: gameResultAgencyConfig.name,
      id: gameResultAgencyConfig.id,
      name: gameResultAgencyConfig.name,
    };

    changeProvider(provider);
    history.push('/provider-draws');
  };

  handleLotteryDrawsBtnClick = (data) => {
    const { changeLotteryType, history } = this.props;

    changeLotteryType(data);
    history.push('/draws');
  };

  handlePageChange = (pageNumber) => {
    this.props.getLotteries(pageNumber);
    this.props.onPaginationPageChange(pageNumber);
  };

  handleChangeEntriesNumber = (pageNumber, entries) => {
    const { pagiNumber } = this.props;

    this.props.changeEntries(entries);
    this.props.getLotteries(pagiNumber);
  };

  handleDeleteItem = (id) => {
    this.props.deleteLottery(id);
  };

  handlePhraseSearch = (phrase) => {
    this.props.getLotteries(1, phrase);
  };

  render() {
    const { lotteriesData, lotteriesError, lotteriesPending, lotteriesHeaders, pagiNumber } = this.props;

    return (
      <AccessControl
        roles={[userRoles.superAdmin, userRoles.admin, userRoles.brand, userRoles.resultsOperator, userRoles.reportsManager]}
        forbiddenRedirect={'/'}
      >
        <div>
          <Page.Header>
            <div className="d-flex align-items-center justify-content-between w-100">
              <Page.Title>Lotteries</Page.Title>
              <AccessControl roles={[userRoles.superAdmin]}>
                <Link to="/lottery/add">
                  <Button color="primary">Add lottery</Button>
                </Link>
              </AccessControl>
            </div>
            <LotteryFilter />
          </Page.Header>
          {lotteriesError && <ErrorHandler error={lotteriesError} />}
          {lotteriesPending && !lotteriesHeaders && <Spinner class="mb-5" />}
          {lotteriesHeaders && (
            <CustomTable
              onChangeEntries={this.handleChangeEntriesNumber}
              onPageChanged={this.handlePageChange}
              onPhraseSearched={this.handlePhraseSearch}
              // handleSearchTermChange={this.props.changeSearchTerm}
              deleteHandler={this.handleDeleteItem}
              paginHeaders={lotteriesHeaders}
              loading={lotteriesPending}
              data={lotteriesData}
              initialPagiPage={pagiNumber}
              columns={[
                {
                  header: 'Name',
                  accessor: 'name',
                  id: 'id',
                  linkto: '/lottery/lotteries',
                  type: 'event',
                  roles: [userRoles.superAdmin, userRoles.admin],
                },
                {
                  header: 'Status',
                  accessor: 'status',
                  type: 'stamp',
                },
                {
                  header: 'Brand',
                  accessor: 'brand.name',
                  id: 'brand.id',
                  linkto: '/brands',
                  type: 'event',
                },
                {
                  header: 'Lottery',
                  accessor: 'lottery.name',
                  type: 'string',
                },
                {
                  header: 'Resolver',
                  accessor: 'prizeResolverName',
                  type: 'string',
                },
                {
                  header: 'Betting',
                  accessor: 'bettingAgencyConfig.name',
                  type: 'string',
                },
                {
                  header: 'Results',
                  accessor: 'gameResultAgencyConfig.name',
                  type: 'string',
                },
                {
                  header: 'Active',
                  accessor: 'active',
                  type: 'boolean',
                },
                {
                  header: 'Draws',
                  type: 'dropdownButton',
                  linksTo: [
                    { link: '/draws', label: 'Lottery draws', accessor: '', action: this.handleLotteryDrawsBtnClick },
                    {
                      link: '/provider-draws',
                      label: 'Provider draws',
                      accessor: 'gameResultAgencyConfig',
                      action: this.handleProviderDrawClick,
                      disabled: 'useProviderDrawLogic',
                    },
                  ],
                  text: 'Draws',
                },
                // {
                //   header: '',
                //   accessor: 'delete',
                //   type: 'delete',
                // },
              ]}
            />
          )}
        </div>
      </AccessControl>
    );
  }
}

LotteryList.propTypes = {
  lotteriesData: PropTypes.array,
  lotteriesError: PropTypes.object,
  lotteriesHeaders: PropTypes.object,
  history: PropTypes.object,
  lotteriesPending: PropTypes.bool,
  getLotteries: PropTypes.func,
  deleteLottery: PropTypes.func,
  changeEntries: PropTypes.func,
  pagiNumber: PropTypes.number,
  onPaginationPageChange: PropTypes.func,
  changeLotteryType: PropTypes.func,
  changeProvider: PropTypes.func,
};

export default connect(
  (state) => ({
    lotteriesError: state.lotteries.error,
    lotteriesPending: state.lotteries.isPending,
    lotteriesData: state.lotteries.data,
    lotteriesHeaders: state.lotteries.headers,
  }),
  {
    getLotteries,
    deleteLottery,
    changeEntries,
    changeLotteryType,
    changeProvider,
  }
)(withPageSettings(LOTTERIES_PAGE)(LotteryList));
