import React, { useState, useEffect } from 'react';
import { useParams, NavLink } from 'react-router-dom';
import httpClient from '../../../services/httpClient';
import AccessControl from '../../AccessControl';
import { userRoles } from '../../../utils/roles';
import { TRANSACTION_TYPES } from '../../../utils/constans/transactionConsts';
import { setProperMoneyValue } from '../../../utils/money';

export default function ProgressiveJackpotFundTransactionsList() {
  //states
  const [lotteryName, setLotteryName] = useState('');
  const [transactions, setTransactions] = useState([]);
  const [intCurrentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(true);

  //params
  const params = useParams();

  //next page
  const handleNextClick = () => {
    //no more records ahead set curr page to max
    if (transactions.length == 0) {
      setCurrentPage(intCurrentPage);
    } else {
      setCurrentPage(intCurrentPage + 1);
    }
  };

  //prev page
  const handlePrevClick = () => {
    //1 is the first page cannot go below it
    if (intCurrentPage <= 1) {
      setCurrentPage(1);
    } else {
      setCurrentPage(intCurrentPage - 1);
    }
  };

  //format date time
  const formatDateTime = (dateTime) => {
    return new Date(dateTime).toLocaleString();
  };

  //get transaction type names
  const getTransactionTypeNames = (intTypeId) => {
    const obj = TRANSACTION_TYPES.find((t) => t.value == intTypeId);
    return obj ? obj.label : '';
  };

  const fetchJackpotTransactions = async () => {
    try {
      //start loading
      setLoading(true);

      //fetch the lottery
      const objResponseLottery = await httpClient.get(`/api/v1/games/${params.id}`);
      const objLottery = await objResponseLottery.data;
      if (objLottery) {
        setLotteryName(objLottery.name);
      }

      //fetch all transactions
      const objResponseTransactions = await httpClient.get(
        `/api/v1/${params.id}/progressive-jackpot-fund-transaction?perPage=10&page=${intCurrentPage}`
      );
      const arrTransactions = await objResponseTransactions.data;
      setTransactions(arrTransactions);

      //end loading
      setLoading(false);
    } catch (err) {
      if (err.response) {
        setError(err.response.data);
      }
    }

    //end loading
    setLoading(false);
  };

  //on mount
  useEffect(() => {
    fetchJackpotTransactions();
  }, [intCurrentPage]);

  //loading
  if (loading) {
    return (
      <div className="spinner-container text-center mt-5">
        <svg className="spinner" width="50px" height="50px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
          <circle className="path" fill="none" cx="33" cy="33" r="30"></circle>
        </svg>
      </div>
    );
  }

  //error
  if (error && error.code) {
    return (
      <div className="pt-4" style={{ color: 'red' }}>
        <h1>Error</h1>
        <p>{JSON.stringify(error, null, 2)}</p>
      </div>
    );
  }

  //pagination
  const RenderPagination = () => {
    return (
      <div className="d-flex mt-5 justify-content-center">
        <ul className="pagination">
          <li className="page-item">
            <a onClick={handlePrevClick} className="page-link" role="button">
              &lt;
            </a>
          </li>
          <li className="p-2">
            <strong>Page {intCurrentPage}</strong>
          </li>
          <li className="page-item">
            <a onClick={handleNextClick} className="page-link" role="button">
              &gt;
            </a>
          </li>
        </ul>
      </div>
    );
  };

  //return the component
  return (
    <AccessControl
      roles={[userRoles.superAdmin, userRoles.admin, userRoles.brand, userRoles.resultsOperator, userRoles.reportsManager]}
      forbiddenRedirect={'/'}
    >
      <div className="pt-4">
        <div className="row row row-cards row-deck">
          <div className="col col-12">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">
                  <strong>{lotteryName} - Progressive Jackpot Fund Transactions</strong>
                </h5>

                <RenderPagination />
                <div className="data-table">
                  <table className="table table-striped table-hover">
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">
                          <div>Id</div>
                        </th>
                        <th scope="col">
                          <div>Source Game Draw</div>
                        </th>
                        <th scope="col">
                          <div>Sales Value</div>
                        </th>
                        <th scope="col">
                          <div>PJP Fund %</div>
                        </th>
                        <th scope="col">
                          <div>PJP Contribution</div>
                        </th>
                        <th scope="col">
                          <div>Type</div>
                        </th>
                        <th scope="col">
                          <div>Value Before Transaction</div>
                        </th>
                        <th scope="col">
                          <div>Value After Transaction</div>
                        </th>
                        <th scope="col">
                          <div>Created At</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {transactions.length == 0 ? <div className="pt-2 text-center">No more transactions.</div> : ''}
                      {transactions.map((transaction, idx) => {
                        return (
                          <tr key={idx}>
                            <td>
                              <div>{transaction.id}</div>
                            </td>
                            <td>
                              <div>
                                {transaction.sourceGameDraw && transaction.sourceGameDraw.id ? (
                                  <NavLink to={`/draws/${transaction.sourceGameDraw.id}`}>
                                    {transaction.sourceGameDraw.id}
                                  </NavLink>
                                ) : (
                                  ''
                                )}
                              </div>
                            </td>
                            <td>
                              <div>{transaction.drawSalesSum ? setProperMoneyValue(transaction.drawSalesSum) : ''}</div>
                            </td>
                            <td>
                              <div>
                                {transaction.drawSalePercent
                                  ? (Math.round(transaction.drawSalePercent * 100) / 100).toFixed(2) + '%'
                                  : ''}
                              </div>
                            </td>
                            <td>
                              <div>{transaction.value ? setProperMoneyValue(transaction.value) : ''}</div>
                            </td>
                            <td>
                              <div>{transaction.type ? getTransactionTypeNames(transaction.type) : ''}</div>
                            </td>
                            <td>
                              <div>
                                {transaction.foundValueBeforeTransaction
                                  ? setProperMoneyValue(transaction.foundValueBeforeTransaction)
                                  : ''}
                              </div>
                            </td>
                            <td>
                              <div>
                                {transaction.foundValueAfterTransaction
                                  ? setProperMoneyValue(transaction.foundValueAfterTransaction)
                                  : ''}
                              </div>
                            </td>
                            <td>
                              <div>{transaction.createdAt ? formatDateTime(transaction.createdAt) : ''}</div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <RenderPagination />
              </div>
            </div>
          </div>
        </div>
      </div>
    </AccessControl>
  );
}
